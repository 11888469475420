import { useStore } from '@/app/use/store';
import { computed } from 'vue';
import { redirectToRoute } from '@/app/router/index';
import decode from 'jwt-decode';
import { removeTokensFromLocalStorage, setTokens } from '@/app/helpers/auth';

const DEFAULT_USER_DATA = {
  isLogged: false,
  name: 'Unknown',
  credits: 0,
  isPremium: false, 
  memberName: '',
  requestedRoute: '/',
  roles: '',
  permissions: '',
  tokens: {},
};

const { store, setData, reset } = useStore(DEFAULT_USER_DATA);

export const useUser = () => {
  const login = tokens => {
    const { sub, credits, premium, memberName, roles, permissions } = decode(tokens.access_token);
    const userObject = { isLogged: true, name: sub, credits, isPremium: premium, memberName, roles, permissions, tokens };
    setTokens(tokens, userObject);
    setData(userObject);
    if ( store.value.requestedRoute === '/') {
      if (permissions.includes('ROLE_ADMIN')) {
        redirectToRoute('/admin/to-reply');
      } else {
        redirectToRoute('/members');
      }
    } else {
      redirectToRoute(store.value.requestedRoute);
    }
  };

  const logout = () => {
    removeTokensFromLocalStorage();
    reset();
    redirectToRoute('/');
  };

  const updateCredits = (newCredits) => {
    store.value.credits = newCredits;
  }

  const updateMemberName = (newMemberName) => {
    store.value.memberName = newMemberName;
  }

  const isLogged = computed(() => store.value.isLogged);
  const refreshToken = computed(() => store.value.refreshToken);
  const accessToken = computed(() => store.value.accessToken);
  const requestedRoute = computed(() => store.value.requestedRoute);
  const userName = computed(() => store.value.name);
  const permissions = computed(() => store.value.permissions);
  const memberName = computed(() => store.value.memberName);
  const credits = computed(() => store.value.credits);
  const isPremium = computed(() => store.value.isPremium);

  return {
    user: store,
    isLogged,
    refreshToken,
    accessToken,
    requestedRoute,
    setUserData: setData,
    clearUserData: reset,
    login,
    logout,
    userName,
    permissions,
    memberName,
    credits,
    updateCredits,
    isPremium,
    updateMemberName
  };
};
