<template>
  <div>
    <router-view v-if="isInstanceInfoFetched" :instance-info="instanceInfo" :company-name="companyName" @update:currency="updateCurrency"/>
  </div>
  <div v-if="fetchError" style="text-align: center;">
    <h1>Website is under maintenance at the moment</h1>
    <h2>Please refresh page in few minutes</h2>
  </div>
</template>

<script>
import { ref } from 'vue';
import { fetchInstanceInfo } from '@/app/api/instance';

export default {
  mounted: function () {
    document.getElementById('cookies-script')?.remove();
    const cookiesPlugin = document.createElement('script');
    cookiesPlugin.src = '/theme/001/js/cookies.js';
    cookiesPlugin.id = 'cookies-script';
    cookiesPlugin.async = true;
    document.body.appendChild(cookiesPlugin);
  },
  setup() {
    const isInstanceInfoFetched = ref(false);
    const fetchError = ref(false);

    const instanceInfo = ref(null);
    const companyName = ref('');

    const setStyles = () => {
      document.getElementById('default-style')?.remove();
      const stylesPlugin = document.createElement('style');
      stylesPlugin.id = 'default-style';
      stylesPlugin.innerHTML = `:root {
                  --clr-theme: ${instanceInfo.value.primaryColor};
                  --clr-secondary: ${instanceInfo.value.secondaryColor};
                  --hero-img: url(../images/hero/${instanceInfo.value.backgroundImage});
      }`;
      document.body.appendChild(stylesPlugin);
    }

    const bundlesMap = new Map();
    bundlesMap.set('EUR', [{ credits: 10, tariff: 17.5 }, { credits: 25, tariff: 40 }, { credits: 50, tariff: 75 }, { credits: 100, tariff: 135 }]);
    bundlesMap.set('USD', [{ credits: 10, tariff: 17.5 }, { credits: 25, tariff: 40 }, { credits: 50, tariff: 75 }, { credits: 100, tariff: 135 }]);
    bundlesMap.set('GBP', [{ credits: 10, tariff: 10 }, { credits: 25, tariff: 40 }, { credits: 50, tariff: 77.5 }, { credits: 100, tariff: 150 }]);
    bundlesMap.set('AUD', [{ credits: 10, tariff: 25 }, { credits: 25, tariff: 60 }, { credits: 50, tariff: 115 }, { credits: 100, tariff: 220 }]);
    bundlesMap.set('CAD', [{ credits: 10, tariff: 25.9 }, { credits: 25, tariff: 59.99 }, { credits: 50, tariff: 110 }, { credits: 100, tariff: 199.99 }]);
    bundlesMap.set('NZD', [{ credits: 10, tariff: 27 }, { credits: 25, tariff: 65 }, { credits: 50, tariff: 130 }, { credits: 100, tariff: 240 }]);
    bundlesMap.set('JPY', [{ credits: 10, tariff: 2700 }, { credits: 25, tariff: 6200 }, { credits: 50, tariff: 11500 }, { credits: 100, tariff: 21000 }]);

    fetchInstanceInfo()
      .then(async data => {
        instanceInfo.value = data;
        companyName.value = instanceInfo.value.companyName.split('').reverse().join('');
        setStyles();
        document.title = instanceInfo.value.name;
        instanceInfo.value.subscriptionPlan = instanceInfo.value.subscriptionPlans.find(plan => plan.currency == instanceInfo.value.defaultCurrency);
        instanceInfo.value.bundles = bundlesMap.get(instanceInfo.value.subscriptionPlan.currency);
        isInstanceInfoFetched.value = true;
      })
      .catch(() => { fetchError.value = true; });

    const updateCurrency = (newValue) => {
      instanceInfo.value.subscriptionPlan = instanceInfo.value.subscriptionPlans.find(plan => plan.currency == newValue);
      instanceInfo.value.bundles = bundlesMap.get(instanceInfo.value.subscriptionPlan.currency);
    };

    return { isInstanceInfoFetched, fetchError, instanceInfo, updateCurrency, companyName };
  },
};
</script>
