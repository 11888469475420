import axios from 'axios';
import { getApiUrl } from '@/app/helpers/axios';
import { useUser } from '@/app/use/user';
import {
  getAccessToken,
  getRefreshToken,
  isTokenValid,
  saveTokensInLocalStorage,
} from '@/app/helpers/auth';
import { refreshTokenRequest } from '@/app/api/auth';
import decode from 'jwt-decode';

export const http = axios.create({
  baseURL: getApiUrl(),
});

export const auth = axios.create({
  baseURL: `${getApiUrl()}/auth`,
});

// Response interceptor
http.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      const { logout } = useUser();
      logout();
    }

    console.error(error.response);

    return Promise.reject(error);
  }
);

// Request interceptor
http.interceptors.request.use(config => {
  // eslint-disable-next-line no-prototype-builtins
  if (config.headers.hasOwnProperty('Authorization')) {
    return config;
  }

  const authorizationToken = getAccessToken();
  if (isTokenValid(authorizationToken)) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${authorizationToken}`;

    return config;
  }

  const refreshToken = getRefreshToken();
  if (isTokenValid(refreshToken)) {
    const { setUserData, updateCredits } = useUser();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${refreshToken}`;
    refreshTokenRequest(refreshToken).then(newTokens => {
      saveTokensInLocalStorage(newTokens);
      setUserData({ tokens: newTokens });
      const { credits } = decode(newTokens.access_token);
      updateCredits(credits);
    });
  }

  return config;
});

// Response interceptor
auth.interceptors.response.use(
  response => response,
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);
