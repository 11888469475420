export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
  {
    path: '/children-protection',
    name: 'ChildrenProtection',
    component: () => import(/* webpackChunkName: "ChildrenProtection" */ '@/views/ChildrenProtection.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "Terms" */ '@/views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy.vue'),
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "Pricing" */ '@/views/Pricing.vue'),
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "Cookies" */ '@/views/Cookies.vue'),
  },
  {
    path: '/disclaimer',
    name: 'Disclaimer',
    component: () => import(/* webpackChunkName: "Disclaimer" */ '@/views/Disclaimer.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Contact.vue'),
  },
  {
    path: '/members',
    name: 'Members',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Members" */ '@/views/Members.vue'),
  },
  {
    path: '/wishlist',
    name: 'Wishlist',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Wishlist" */ '@/views/Wishlist.vue'),
  },
  {
    path: '/members/:name',
    name: 'Member',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Member" */ '@/views/Member.vue'),
  },
  {
    path: '/messages/inbox',
    name: 'Inbox',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Inbox" */ '@/views/Inbox.vue'),
  },
  {
    path: '/messages/outbox',
    name: 'Outbox',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Outbox" */ '@/views/Outbox.vue'),
  },
  {
    path: '/messages/conversations',
    name: 'Conversations',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Conversations" */ '@/views/Conversations.vue'),
  },
  {
    path: '/messages/conversations/:uuid',
    name: 'Conversation',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Conversation" */ '@/views/Conversation.vue'),
  },
  {
    path: '/messages/flirts/outbox',
    name: 'FlirtsOutbox',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "FlirtsOutbox" */ '@/views/FlirtsOutbox.vue'),
  },
  {
    path: '/messages/flirts/inbox',
    name: 'FlirtsInbox',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "FlirtsInbox" */ '@/views/FlirtsInbox.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "MyProfile" */ '@/views/MyProfile.vue'),
  },
  {
    path: '/credits/subscription',
    name: 'Subscription',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Subscription" */ '@/views/Subscription.vue'),
  },
  {
    path: '/credits/subscription/direct-premium',
    name: 'SubscriptionDirectPremium',
    component: () => import(/* webpackChunkName: "SubscriptionDirectPremium" */ '@/views/SubscriptionDirectPremium.vue'),
  },
  {
    path: '/credits/subscription/direct-premium/success',
    name: 'DirectPremiumSuccess',
    component: () => import(/* webpackChunkName: "DirectPremiumSuccess" */ '@/views/DirectPremiumSuccess.vue'),
  },
  {
    path: '/credits/subscription/direct-premium/failure',
    name: 'DirectPremiumFailure',
    component: () => import(/* webpackChunkName: "DirectPremiumFailure" */ '@/views/DirectPremiumFailure.vue'),
  },
  {
    path: '/credits/subscription/success',
    name: 'SubscriptionSuccess',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Success" */ '@/views/Success.vue'),
  },
  {
    path: '/credits/failure',
    name: 'TopUpFailure',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Failure" */ '@/views/Failure.vue'),
  },
  {
    path: '/credits/subscription/failure',
    name: 'SubscriptionFailure',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "Failure" */ '@/views/Failure.vue'),
  },
  {
    path: '/credits',
    name: 'TopUp',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "TopUp" */ '@/views/TopUp.vue'),
  },
  {
    path: '/signup/success',
    name: 'SignupSuccess',
    component: () => import(/* webpackChunkName: "SignupSuccess" */ '@/views/SignupSuccess.vue'),
  },
  {
    path: '/activation/:uuid',
    name: 'Activation',
    component: () => import(/* webpackChunkName: "Activation" */ '@/views/Activation.vue'),
  },
  {
    path: '/unsubscribe/:uuid',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "Unsubscribe" */ '@/views/Unsubscribe.vue'),
  },
  {
    path: '/admin/to-reply',
    name: 'ToReplyList',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "ToReplyList" */ '@/views/ToReplyList.vue'),
  },
  {
    path: '/admin/to-reply/conversations/:uuid',
    name: 'ToReplyConversation',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "ToReplyConversation" */ '@/views/ToReplyConversation.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' },
  },
];
