import { createRouter, createWebHistory, useRoute } from 'vue-router';
import { routes } from '@/app/router/routes';
import { computed } from 'vue';
import { useUser } from '@/app/use/user';
import { getAccessToken, getRefreshToken, isTokenValid } from '@/app/helpers/auth';
import { refreshTokenRequest } from '@/app/api/auth';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash }
    }
    // always scroll to top
    return { top: 0 }
  },
});

export const redirectToRoute = route => {
  router.push(route).catch(() => {
    /* */
  });
};

router.beforeEach((to, from, next) => {
  const { isLogged, setUserData, login, logout, permissions} = useUser();

  if (!isLogged.value) {
    const access_token = getAccessToken();
    const refresh_token = getRefreshToken();
    if (isTokenValid(access_token)) {
      login({ access_token, refresh_token });
      next(to);
      return;
    }
    if (isTokenValid(refresh_token)) {
      refreshTokenRequest(refresh_token)
        .then(tokens => {
          login(tokens);
          next(to);
        })
        .catch(() => {
          logout();
          if (to.meta.requiresAuth) {
            next('/login');
          }
        });
      return;
    }
  }

  if (isLogged.value && (to.name == 'Login' || to.name == 'Home')) {
    if (permissions.value.includes('ROLE_ADMIN')) {
      next('/admin/to-reply');
      return;
    } else {
      next('/members');
      return;
    }
  }

  if (isLogged.value || !to.meta.requiresAuth) {
    next();
    return;
  }

  setUserData({ requestedRoute: to });
  next('/login');
});

export const useUrl = () => {
  const route = useRoute();

  const id = computed(() => route.params.id);
  const name = computed(() => route.params.name);
  const uuid = computed(() => route.params.uuid);
  const currentPath = computed(() => route.path);
  const currentRouteName = computed(() => router.currentRoute.value.name);
  const currentRoute = computed(() => router.currentRoute.value);

  return {
    id,
    name,
    uuid,
    currentRouteName,
    currentPath,
    currentRoute
  };
};

export const reload = () => {
  redirectToRoute(useUrl().currentPath);
};

export default router;
